<template>
    <div class="choose-org">
        <div class="home-box">
            <h1>{{user.xingming}},请选择需要管理的公司</h1>
            <div class="home-content flex align-center flex-wrap">
                <div class="org-item-role flex align-center justify-around "
                     v-for="item in orgList" :key="item.id"
                     @click="toOrg(item.id,item.mingcheng)"
                >
                    <h3>{{item.mingcheng}}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {orgList} from "@/api/org";

export default {
    name: "roleList",
    data() {
        return {
            orgList:[]
        }
    },
    created() {
        this.getList()
    },
    computed:{
        user(){
            return this.$store.state.user
        }
    },
    components: {},
    methods: {
        //  获取各公司列表
        async getList() {
            const res = await orgList(this.page, this.limit, this.keyword)
            this.orgList = res.data
            this.$store.state.orgList = this.orgList
            if(this.orgList.length === 1) await this.toOrg(res.data[0].id)
        },
        //  进入某个机构
        toOrg(id,name){

            this.$store.state.orgId = id
            this.$store.state.orgName = name
            localStorage.setItem('orgId',id)
            localStorage.setItem('orgName',name)
            setTimeout(()=>{
                this.$router.replace('/admin/needList')
            },500)
        }
    },
    filters: {},
    watch: {},
}
</script>

<style scoped lang="scss">
.choose-org{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(#368ad1, #fff);
    .home-box{
        display: block;
        width: 1020px;
        margin: 0 auto;
        padding-top: 170px;
        h1{
            font-size: 40px;
            line-height: 60px;
            margin-bottom: 40px;
            letter-spacing: 4px;
            color: #333;
            text-align: center;
        }
        .home-content{
            width: 1020px;
            padding: 30px;
            background-color: #fff;
            box-shadow: 0 8px 18px #ccc;
            border-radius: 8px;
            .org-item-role{
                width: 225px;
                height: 120px;
                background-color: #368ad1;
                border-radius: 4px;
                margin-bottom: 20px;
                margin-right: 20px;
                cursor: pointer;
                &:nth-child(4n){
                    margin-right: 0;
                }
                h3{
                    text-align: center;
                    font-size: 22px;
                    font-weight: bold;
                    color: #fff;
                    padding: 10px 20px;
                }
            }
        }
    }
}
</style>
